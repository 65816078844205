import React from "react";
import AreaChart from "../../Common/Charts/AreaChart1/AreaChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import "./GeneratorArea.css";
import GuageChart1 from "../../Common/Charts/GaugeChart/GaugeChart1";
import BarGraph from "../../Common/Charts/BarGraph/BarGraph";
import AreaChart1 from "../../Common/Charts/AreaChart1/AreaChart1";

export default function GeneratorArea(props) {
  return (
    <>
      {props.activeWaterTemp && (
        <div className="activestatusmain">
          <div className="tablegaugeFuel">
            <table className="tableMain2">
              <thead>
                {props.activeWaterTemp && (
                  <tr>
                    {props.activeWaterTemp && (
                      <th colSpan={2}>LAST ACTIVITY</th>
                    )}
                  </tr>
                )}
                {props.activeTotalChk && (
                  <tr>
                    {props.activeTotalChk && <td> Power Generated KW</td>}
                    {props.activeTotalChk && (
                      <td>{props.activeTotal}</td>
                    )}
                  </tr>
                )}
                {/* {props.activeAvailableFuel && (
                  <tr>
                    {props.activeAvailableFuel && (
                      <td>Available Fuel Ltr</td>
                    )}
                    {props.activeAvailableFuel && (
                      <td>{props.activeAvailableFuel}</td>
                    )}
                  </tr>
                )} */}
                {props.activeFuelAvailableStart && (
                  <tr>
                    {props.activeFuelAvailableStart && (
                      <td>Fuel Available Start Ltr</td>
                    )}
                    {props.activeFuelAvailableStart && (
                      <td>{props.activeFuelAvailableStart}</td>
                    )}
                  </tr>
                )}
                {props.activeFuelAvailableEnd && (
                  <tr>
                    {props.activeFuelAvailableEnd && <td>Fuel Available End Ltr</td>}
                    {props.activeFuelAvailableEnd && <td>{props.activeFuelAvailableEnd}</td>}
                  </tr>
                )}
                {props.activeRefueling && (
                  <tr>
                    {props.activeRefueling && <td>Refueling Ltr</td>}
                    {props.activeRefueling && <td>{props.activeRefueling}</td>}
                  </tr>
                )}
                {props.activeGenRunTime && (
                  <tr>
                    {props.activeGenRunTime && <td>Gen Set Run Time M</td>}
                    {props.activeGenRunTime && (
                      <td>{props.activeGenRunTime}</td>
                    )}
                  </tr>
                )}
                {props.activeWapdaOffMin && (
                  <tr>
                    {props.activeWapdaOffMin && <td>WAPDA Off Minutes M</td>}
                    {props.activeWapdaOffMin && <td>{props.activeWapdaOffMin}</td>}
                  </tr>
                )}

                {props.activeConsumedFuel && (
                  <tr>
                    {props.activeConsumedFuel && <td>Fuel Consumed </td>}
                    {props.activeConsumedFuel && (
                      <td>{props.activeConsumedFuel}</td>
                    )}
                  </tr>
                )}
              </thead>
            </table>
            <table className="tableMain2">
              <thead>
                {props.activeWaterTemp && (
                  <tr>
                    {props.activeWaterTemp && <th colSpan={2}>ENGINE</th>}
                  </tr>
                )}
                {props.activeOilPressure && (
                  <tr>
                    {props.activeOilPressure && <td>Oil Pressure Psi</td>}
                    {props.activeOilPressure && (
                      <td>{props.activeOilPressure}</td>
                    )}
                  </tr>
                )}
                {props.activeWaterTemp && (
                  <tr>
                    {props.activeWaterTemp && <td>Water Temperature °C</td>}
                    {props.activeWaterTemp && <td>{props.activeWaterTemp}</td>}
                  </tr>
                )}
                {props.activeBatteryVoltage && (
                  <tr>
                    {props.activeBatteryVoltage && <td>Battery Voltage V</td>}
                    {props.activeBatteryVoltage && (
                      <td>{props.activeBatteryVoltage}</td>
                    )}
                  </tr>
                )}
                {props.activeRunHours && (
                  <tr>
                    {props.activeRunHours && <td>Run Hours M</td>}
                    {props.activeRunHours && <td>{props.activeRunHours}</td>}
                  </tr>
                )}

                {props.activeLidStatus && (
                  <>
                    {props.activeLidStatus == "OPEN" ? (
                      <tr style={{ color: "green" }}>
                        {props.activeLidStatus && <td>Lid Status</td>}
                        {props.activeLidStatus && (
                          <td>{props.activeLidStatus}</td>
                        )}
                      </tr>
                    ) : (
                      <tr>
                        {props.activeLidStatus && <td>Lid Status</td>}
                        {props.activeLidStatus && (
                          <td>{props.activeLidStatus}</td>
                        )}
                      </tr>
                    )}
                  </>
                )}
                {props.activeWaterLevel && (
                  <tr>
                    {props.activeWaterLevel && <td>Water Level </td>}
                    {props.activeWaterLevel && (
                      <td>{props.activeWaterLevel}</td>
                    )}
                  </tr>
                )}
                {props.activeGenStatus && (
                  <>
                    {props.activeGenStatus == "RUN" ? (
                      <tr style={{ color: "green" }}>
                        {props.activeGenStatus && <td>Engine Status</td>}
                        {props.activeGenStatus && (
                          <td>{props.activeGenStatus}</td>
                        )}
                      </tr>
                    ) : (
                      <tr>
                        {props.activeGenStatus && <td>Engine Status</td>}
                        {props.activeGenStatus && (
                          <td>{props.activeGenStatus}</td>
                        )}
                      </tr>
                    )}
                  </>
                )}
              </thead>
            </table>
          </div>

          <div className="areachart">
            {props.activeChartChk && (
              <>
                <div className="chartunitgraph">
                  {/* <div className="unitName">Pressure</div> */}
                  <AreaChart1
                    labels={props.activestatusLabels}
                    KW={props.activestatusKW}
                    label1="Pressure"
                    label2="Water Temp"
                    Fuel={props.activestatusFuel}
                    speedlabel="KW"
                  />
                </div>
                <div className="graphName">Time</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
