import React from "react";
import "./ReportMachine.css";
import Header from "../Common/Header/Header";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import AreaChart from "../Common/Charts/AreaChart1/AreaChart";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import moment from "moment";

const ReportMachine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location.state.date;
  let array = location?.state?.filterDate;
  let roomName = location?.state?.roomName;
  let ar0 = array[0]?.meterReadings;
  let arr = [].concat(ar0).reverse();
  let tPower = arr[0]?.Total_KW - arr[arr.length - 1]?.Total_KW;
  let tFuel = arr[0]?.Total_Fuel - arr[arr.length - 1]?.Total_Fuel;
  console.log(
    "this isthe array",
    array[0]?.meterReadings?.map((dataa) => dataa?.KW)
  );
  const reportformat = "Jalal Sons\t" + date.startDate + "\t\t" + date.endDate;
  // console.log("The Format of the report is:------",reportformat)
  // console.log("start date is-----",date.startDate,"end date is :-----", date.endDate)
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  // convet min to hours and minutes
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  return (
    <>
      <div className="reportmain">
        <div className="report" id="reportid">
          {/* <div className="reportmaintable"> */}
          <Header
            head={"MRS9000 GENERATOR SET HEALTH MONITORING WITH REPORTING"}
            roomName={roomName}
          />
          <div className="name">Events Report</div>
          <div className="date">
            {date.startDate} ~ {date.endDate}
          </div>
          <table className="tablemain" id="tablee">
            <thead className="tablehead">
              <tr className="tablerow">
                {arr[arr.length - 1]?.date && (
                  <th className="tableh datetimetable">Time | Date</th>
                )}
                {arr[arr.length - 1]?.KW && (
                  <th className="tableh headtable">Total KW </th>
                  )}
                {arr[arr.length - 1]?.Fuel_Level && (
                  <th className="tableh headtable">Fuel % </th>
                  )}
                  {arr[arr.length - 1]?.KW && (
                    <th className="tableh headtable">Generated KW </th>
                    )}
                  {arr[arr.length - 1]?.Gen_Runtime && (
                    <th className="tableh headtable">Gen Run Time </th>
                  )}
                {/* {arr[arr.length - 1]?.Available_Fuel && (
                  <th className="tableh headtable">Consumed Fuel Ltr</th>
                )} */}
                {/* {arr[arr.length - 1]?.Fuel_Flow && (
                  <th className="tableh headtable">Fuel Flow</th>
                )} */}
                {arr[arr.length - 1]?.KW && (
                  <th className="tableh headtable">Fuel Consumed</th>
                )}
                {arr[arr.length - 1]?.Available_Fuel && (
                  <th className="tableh headtable">Available Fuel</th>
                )}
                {arr[arr.length - 1]?.Fuel_Temperature && (
                  <th className="tableh headtable">Fuel Temp</th>
                )}
                {/* {arr[arr.length - 1]?.Tank_Volume && (
                  <th className="tableh headtable">Tank Volume</th>
                )} */}
                {/* {arr[arr.length - 1]?.Frequency && (
                  <th className="tableh headtable">Frequency</th>
                )} */}
                {/* {arr[arr.length - 1]?.Wapda_OFFTime && (
                  <th className="tableh headtable">WAPDA Off Time</th>
                )} */}
                {arr[arr.length - 1]?.Run_Hours && (
                  <th className="tableh headtable">Run Hours M</th>
                )}
                {arr[arr.length - 1]?.Battery_Voltage && (
                  <th className="tableh headtable">Battery Voltage</th>
                )}
                {arr[arr.length - 1]?.Water_Temperature && (
                  <th className="tableh headtable">Water Temp °C </th>
                )}
                {/* {arr[arr.length - 1]?.Oil_Pressure && (
                  <th className="tableh headtable">Oil Pressure Psi</th>
                )} */}
                {arr[arr.length - 1]?.Water_Level && (
                  <th className="tableh headtable">Water Level</th>
                )}
                {arr[arr.length - 1]?.Lid_Status && (
                  <th className="tableh headtable">Lid Status</th>
                )}
                {arr[arr.length - 1]?.Wapda && (
                  <th className="tableh headtable">WAPDA</th>
                )}
                {arr[arr.length - 1]?.Generator_Status && (
                  <th className="tableh genheadtable">Engine Status</th>
                )}
                {arr[arr.length - 1]?.Generator_StatusLOAD && (
                  <th className="tableh genheadtable">Gen Status</th>
                )}
              </tr>
            </thead>
            <tbody className="tablebody">
              {arr.map((ddd, i) => {
                return (
                  <tr className="tablerow" key={i}>
                    {arr[arr.length - 1]?.date && (
                      <td
                        className="tabled"
                        // onClick={() => {
                        //   let filterHour = array?.map((meter) => {
                        //     return {
                        //       ...meter,
                        //       HourReadings: meter?.HourReadings.filter(
                        //         (val) => {
                        //           let dateSearch = val?.date;
                        //           if (dateSearch === ddd?.date) {
                        //             return true;
                        //           } else {
                        //             return false;
                        //           }
                        //         }
                        //       ),
                        //     };
                        //   });
                        //   navigate(`/dashboard/reportHour`, {
                        //     state: {
                        //       filterDateHour: filterHour,
                        //       date: date,
                        //       roomName: roomName,
                        //     },
                        //   });
                        // }}
                      >
                        {ddd?.time} | {ddd?.date}
                      </td>
                    )}

                    {arr[arr.length - 1]?.KW && (
                      <td className="tabled">
                        {/* {parseInt(ddd?.KW).toLocaleString()} |{" "} */}
                        {parseInt(ddd?.Total_KW).toLocaleString()}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.Fuel_Level && (
                      <td className="tabled">
                        {parseInt(ddd?.Fuel_Level).toLocaleString()}
                        {/* {parseInt(ddd?.Total_Fuel).toLocaleString()}{" "} */}
                      </td>
                    )}
                    {arr[arr.length - 1]?.KW && (
                      <td className="tabled">
                        {parseInt(ddd?.Total).toLocaleString()}
                      </td>
                    )}
                        {arr[arr.length - 1]?.Gen_Runtime && (
                          <td className="tabled">
                            {parseInt(ddd?.Gen_Runtime).toLocaleString()}
                          </td>
                        )}
                    {arr[arr.length - 1]?.Fuel_Level && (
                      <td className="tabled">
                        {parseInt(ddd?.Fuel_Consumed).toLocaleString()}{" "}
                      </td>
                    )}
                    {/* {arr[arr.length - 1]?.Fuel_Level && (
                      <td className="tabled">
                        {parseInt(ddd?.consumed_Fuel).toLocaleString()}{" "}
                      </td>
                    )} */}
                    {/* {arr[arr.length - 1]?.Fuel_Flow && (
                      <td className="tabled">
                        {parseInt(ddd?.Fuel_Flow).toLocaleString()}{" "}
                      </td>
                    )} */}
                    
                    {arr[arr.length - 1]?.Available_Fuel && (
                      <td className="tabled">
                        {parseInt(ddd?.Available_Fuel).toLocaleString()}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.Fuel_Temperature && (
                      <td className="tabled">
                        {parseInt(ddd?.Fuel_Temperature).toLocaleString()}{" "}
                      </td>
                    )}
                    {/* {arr[arr.length - 1]?.Tank_Volume && (
                      <td className="tabled">
                        {parseInt(ddd?.Tank_Volume).toLocaleString()}{" "}
                      </td>
                    )} */}
                    {/* {arr[arr.length - 1]?.Frequency && (
                      <td className="tabled">
                        {parseFloat(ddd?.Frequency).toFixed(1)}
                      </td>
                    )} */}
                    {/* {arr[arr.length - 1]?.Wapda_OFFTime && (
                      <td className="tabled">
                        {(ddd?.Wapda_OFFTime)}
                      </td>
                    )} */}
                    {arr[arr.length - 1]?.Run_Hours && (
                      <td className="tabled">
                        {(ddd?.Run_Hours)}
                      </td>
                    )}
                    {arr[arr.length - 1]?.Battery_Voltage && (
                      <td className="tabled">
                        {parseInt(ddd?.Battery_Voltage).toLocaleString()}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.Water_Temperature && (
                      <td className="tabled">{ddd?.Water_Temperature}</td>
                    )}
                    {/* {arr[arr.length - 1]?.Oil_Pressure && (
                      <td className="tabled">{ddd?.Oil_Pressure} </td>
                    )} */}
                    {arr[arr.length - 1]?.Water_Level && (
                      <td className="tabled">{ddd?.Water_Level} </td>
                    )}
                    {arr[arr.length - 1]?.Lid_Status && (
                      <td className="tabled">
                        {ddd?.Lid_Status}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.Wapda && (
                      <td className="tabled">
                        {ddd?.Wapda}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.Generator_Status && (
                      <td className="tabled">{ddd?.Generator_Status}</td>
                    )}
                    {arr[arr.length - 1]?.Generator_StatusLOAD && (
                      <td className="tabled">{ddd?.Generator_StatusLOAD}</td>
                    )}
                  </tr>
                );
              })}
              <tr className="tabletotal">
                {arr[arr.length - 1]?.date && (
                  <th className="tablehead tableh">Total</th>
                )}
                {arr[arr.length - 1]?.KW && (
                  <td className="tabled tablehead">
                    {parseInt(tPower).toLocaleString()}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
        <div className="chartunitgraph">
          {/* <div className="unitName">KW</div> */}
          <AreaChart
            labels={array[0]?.meterReadings?.map((time) => moment(time?.dt).format("DD | HH:mm"))}
            KW={array[0]?.meterReadings?.map((dataa) => dataa?.Total)}
            Fuel={array[0]?.meterReadings?.map((dataa) => dataa?.Available_Fuel)}
            GenRunTime={array[0]?.meterReadings?.map((dataa) => dataa?.Gen_Runtime)}
            label1 = "KW"
            label2 = "Fuel"
            label3 = "Run Time"
            speedlabel="KW"
          />
        </div>
          <div className="graphName">Time</div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ReportMachine;
