import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
  import './BarGraph.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarGraph(props) {
  const options = {
    type: "bar",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 10,
            family: "vazir",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
            family: "vazir",
          },
        },
        beginAtZero: true,
        max: 180,
        // max: props.maxValueArea,
      },
    },
  };
  const labels = props.labels;

  const label1 = props.label1
  const label2 = props.label2
  const label3 = props.label3

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: label1,
        //   data: [10,42,13,74,25,23,54,65],
        data: props.KW,
        borderColor: "rgb(41, 128, 185 )",
        backgroundColor: "rgba(41, 128, 185,0.6 )",
        // backgroundColor: 'rgba(169, 204, 227, 0.7)',
        radius: 3,
        borderJoinStyle: "round",
      },
      {
        fill: true,
        label: label2,
        //   data: [10,42,13,74,25,23,54,65],
        data: props.Fuel,
        borderColor: "rgb(211, 84, 0 )",
        backgroundColor: "rgba(211, 84, 0,0.7 )",
        // backgroundColor: 'rgba(169, 204, 227, 0.7)',
        radius: 3,
        borderJoinStyle: "round",
      },
      {
        fill: true,
        label: label3,
        //   data: [10,42,13,74,25,23,54,65],
        data: props.GenRunTime,
        borderColor: "rgb(0, 128, 0)",
        backgroundColor: "rgba(0, 128, 0,0.6)",
        // backgroundColor: 'rgba(169, 204, 227, 0.7)',
        radius: 3,
        borderJoinStyle: "round",
      },

    ],
  };
  return (
    <>
    <div className="bargraphMain">
      <Bar options={options} data={data} />
    </div>
    </>
  );
}
