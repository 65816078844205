import React from 'react'
import './HeaderLogin.css'
import jalalson from '../../../Asserts/Logo/jalalson.png'


export default function Header(props) {

    return (
        <>
            <div className="machineHeaderLogin">
                <img className='machineimagelogoLogin' src={jalalson} alt="Uploading" />
                {/* <div className="machineheadingLogin">Online Monitoring & Reporting System</div> */}
                <div className="datetimeupdatedLogin">{props.timedate}</div>
            </div>
        </>
    )
}
