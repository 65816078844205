import React from 'react'
import './Header.css'

import MenuProvider from "react-flexible-sliding-menu";
import Menu from '../Charts/SlideableManu/Menu';
import SlideableManu1 from '../Charts/SlideableManu/SlideableManu1';


export default function Header(props) {

    return (
        <>
            <div className="machineHeader">
            <div className="sidemanubar">
                    {/* <MenuProvider MenuComponent={Menu}> */}
                        <MenuProvider MenuComponent={Menu} animation="push">
                        <SlideableManu1 />
                    </MenuProvider>
                </div>
                {
                    props.roomName && 
                <span>{props.roomName}</span>
                }
                {/* <div className="DeviceIDheading">{props.DeviceID}</div> */}
                <div className="machineheading">{props.head}</div>
                {props.timedate && <div className="datetimeupdated">{props.timedate}</div>}
            </div>
        </>
    )
}
