import React from "react";
import "./Footer.css";
// import hunch from "../../../Asserts/Logo/hunch.png";
import omni from '../../../Asserts/Logo/Omni.png'

export default function FooterLogin(props) {
  return (
    <>
      <div className="footer">
        <div className="btns">
          {/* <GoBack />
          <Logout /> */}
        </div>
        <div className="develop">
          <div className="develophead"><strong className="projectName">MRS9000</strong> is a Monitoring & Reporting System using Amazon Web Server based on latest development frontend and backend algorithm tools with database. Our vision is to automate industries with MRS9000 to get the customer optimum performance of their machines and manpower utilization. For further information regarding our products and services please visit our site</div>
          <div className="develophead1"><strong className="projectName">MRS9000</strong> Monitoring & Reporting System</div>
          <img className="hunchlogo" src={omni} alt="Uploading" />
        </div>
      </div>
    </>
  );
}
