import React, {useContext} from 'react';
import { MenuContext } from "react-flexible-sliding-menu";
import './SlideableManu1.css';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import jalalson from '../../../../Asserts/Logo/jalalson.png'


export default function SlideableManu1() {
    const { toggleMenu } = useContext(MenuContext);
  return (
    <>
    <button onClick={toggleMenu} className="primary-button">
        {/* Toggle Menu */}
        {/* <AlignHorizontalLeftIcon/> */}
        <img className='twimg' src={jalalson} alt={<AlignHorizontalLeftIcon/>} />
    </button>
    </>
  )
}
