import React, { useEffect, useState } from "react";

// API
import { GetMachineData } from "../../Service/MachineApi";

// Helper
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsArrowLeftShort } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import Clock from "react-live-clock";
import jalalson from '../../Asserts/Logo/jalalson.png'

// Styling
import "./Department.scss";

// Other Components
import HeaderLogin from "../Common/Header/HeaderLogin";
import { CircularProgress } from "@mui/material";

export default function Department() {
  const navigate = useNavigate();
  const [DepartmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false)

  const gettingDepartmentData = async () => {
    setLoading(true)
    let res = await GetMachineData();
    if (res?.error != null) {
      toast.error(res.error);
    } else {
      setDepartmentData(res?.data);
    }
    setLoading(false)
  };
  useEffect(() => {
    gettingDepartmentData();
  }, []);

  const goNext = (data) => {
    navigate(`/dashboard/Dep?MQTT_ID=${data?.MQTT_ID}`);
  };

  return (
    <>
      <HeaderLogin
        timedate={
          <Clock
            format={"HH:mm:ss | DD-MM-YYYY"}
            ticking={true}
            timezone={"asia/Karachi"}
          />
        }
      />
      {
        loading ?
        <>
        <div className="loading">
        <CircularProgress />
        </div>
        </>
        :
        <>
      <div className="MainDepartmentWithFooter">
        <div className="departmentMainContainer">
          <div className="departmentMain">
            <div className="departmentHeader">
              <div className="headleft">
                <div className="heading">Branches</div>
              </div>
            </div>
            {DepartmentData?.[0] && (
              <div className="departmentCard">
                {DepartmentData?.map((data, i) => {
                  return (
                    <div
                      key={i}
                      className="departmentmain"
                      onClick={() => goNext(data)}
                    >
                      <div className="heading">
                        <img src={jalalson} alt="uploading" className="branchImg"/>
                        <div className="deviceName">{data?.machineName}</div>
                        <div className="deviceID">{data?.Device_ID}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      </>
      }
    </>
  );
}
