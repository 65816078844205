import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { login } from "../../../Service/AuthApi";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HeaderLogin from "../../Common/Header/HeaderLogin";
import FooterLogin from "../../Common/Footer/FooterLogin";

import Clock from 'react-live-clock';
import advertise from '../../../Asserts/Logo/advertise.jpg'
import jalalson from '../../../Asserts/Logo/jalalson.png'

import { Input } from "antd";

const defaultvalue = {
  username: "",
  password: "",
};

export default function Login() {
  const [userlogin, setuserlogin] = useState(defaultvalue);

  const navigate = useNavigate();

  const onvaluechange = (e) => {
    setuserlogin({ ...userlogin, [e.target.name]: e.target.value });
  };

  const loginClick = async () => {
    let response = await login(userlogin);
    if (response?.data[0]?.admin) {
      toast.success("Admin Login Success", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/area");
      }, 1000);
    } else if (response?.data[0]?.admin === false) {
      toast.success("User Login Success", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate(`/area`);
      }, 1000);
    } else {
      toast.error("Invalid Cridentials", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    localStorage.setItem("login", JSON.stringify(response?.data));
  };


  return (
    <>
      <div className="loginmainContainer">
        <div className="loginmain">
          <div className="header">
          
            
        <HeaderLogin
          timedate={
            <Clock
            format={"HH:mm:ss | DD-MM-YYYY"}
            ticking={true}
            timezone={"asia/Karachi"}
            />
          }
        />
        </div>
        <div className="logincontainer">
          <div className="loginform">
            <div className="headinglogin">Login</div>
            <Input
              className="logininput"
              onChange={(e) => onvaluechange(e)}
              type="text"
              name="username"
              id="username"
              placeholder="Username"
            />
            <Input.Password
              className="logininput"
              onChange={(e) => onvaluechange(e)}
              type="password"
              name="password"
              id="password"
              placeholder="Password"
            />
            <button className="btnlogin" type="submit" onClick={loginClick}>
              {" "}
              Login{" "}
            </button>
            <ToastContainer />
          </div>
        </div>
        </div>
        <div className="logincontent">
          <div className="loginContentHead">
            <img className="omniImage" src={jalalson} alt="Uploading" />
            <div className="loginContentHeading">
              <h3>Industory Automation</h3>
              <br />
            </div>
          </div>
          <div className="loginContentPara">
            Our vision is to automate industries to get optimum performence
            using:
            <ul type="i">
              <li>* Online Monitoring & Reporting System using Amazon (AWS)</li>
              <li>* Embedded SCB & field instruments</li>
              <li>* Internet of Things (IoT)</li>
            </ul>
            <div className="paraContent">
              Industrial automation uses software based AI technology to
              imporove machines processes which in performing specific
              functions and quality control. The initial goals of
              industrial automation has to focused on increasing productivity by
              extending work hours, and reducing the costs of ownership.
            </div>
          </div>
          <img className="advertise" src={advertise} alt="" />
        </div>
        <div className="footer">
        </div>

      </div>
        <FooterLogin />
    </>
  );
}
